import theme from 'styles/themes/default';

type Props = {
  color?: string;
  direction?: string;
};

function CaretUpDown({
  color = theme.colors.white.primary,
  direction = 'up',
  ...props
}: Props) {
  const transform =
    direction === 'down' ? 'scale(1, -1) translate(0, -100)' : '';

  return (
    <svg
      data-name="Layer 1"
      fill={color}
      viewBox="0 0 100 100"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <defs>
        <clipPath id="clip-path">
          <path d="M491.89,1191.71a1.93,1.93,0,0,0-3.2,1.47v8.33h-9.6c-4.79,0-9.94-5.35-11.63-7.11l-.52-.53a105.65,105.65,0,0,1-8.1-9.37,104.48,104.48,0,0,1,8.1-9.37l.52-.54c1.69-1.75,6.85-7.1,11.62-7.1h9.6v8.33a1.93,1.93,0,0,0,1.13,1.75,1.88,1.88,0,0,0,.8.17,1.93,1.93,0,0,0,1.26-.47l14.5-12.5a1.93,1.93,0,0,0,0-2.93l-14.5-12.5a1.93,1.93,0,0,0-3.2,1.47v8.33h-9.6c-8.32,0-15.08,7-17.62,9.64l-.42.43a104.62,104.62,0,0,0-7.52,8.54,119.82,119.82,0,0,0-9.16-10.45c-5.19-5.19-11.9-8.16-18.4-8.16H412.51a4.17,4.17,0,0,0,0,8.33H426c4.24,0,8.92,2.14,12.5,5.72a122,122,0,0,1,9.75,11.29,123.1,123.1,0,0,1-9.75,11.29c-3.59,3.58-8.26,5.72-12.5,5.72H412.51a4.17,4.17,0,1,0,0,8.33H426c6.5,0,13.21-3,18.4-8.17a120.46,120.46,0,0,0,9.16-10.45,103.82,103.82,0,0,0,7.52,8.53l.41.42c2.55,2.65,9.31,9.66,17.64,9.66h9.6v8.33a1.93,1.93,0,0,0,1.13,1.75,1.88,1.88,0,0,0,.8.17,1.93,1.93,0,0,0,1.26-.47l14.5-12.5a1.93,1.93,0,0,0,0-2.93Z" />
        </clipPath>
        <clipPath id="clip-path-2">
          <path d="M-52.41-89.81a44.07,44.07,0,0,0-.7-7.85H-89.23v14.84h20.64a17.65,17.65,0,0,1-7.65,11.58v9.62h12.4C-56.59-68.3-52.41-78.13-52.41-89.81Z" />
        </clipPath>
        <clipPath id="clip-path-3">
          <path d="M-89.23-52.33c10.36,0,19-3.43,25.39-9.29l-12.4-9.62a23.05,23.05,0,0,1-13,3.66A22.86,22.86,0,0,1-110.7-83.4h-12.81v9.94A38.34,38.34,0,0,0-89.23-52.33Z" />
        </clipPath>
        <clipPath id="clip-path-4">
          <path d="M-110.7-83.4a23.06,23.06,0,0,1-1.2-7.29A23.06,23.06,0,0,1-110.7-98v-9.94h-12.81a38.34,38.34,0,0,0-4.08,17.23,38.34,38.34,0,0,0,4.08,17.23Z" />
        </clipPath>
        <clipPath id="clip-path-5">
          <path d="M-89.23-113.79a20.72,20.72,0,0,1,14.66,5.74l11-11a36.86,36.86,0,0,0-25.66-10,38.34,38.34,0,0,0-34.28,21.13L-110.7-98A22.86,22.86,0,0,1-89.23-113.79Z" />
        </clipPath>
      </defs>
      <polygon
        points="49.98 24.09 0.32 73.76 6.55 79.99 49.98 36.56 93.67 80.25 99.9 74.01 49.98 24.09"
        transform={transform}
      />
      <path d="M491.89,1191.71a1.93,1.93,0,0,0-3.2,1.47v8.33h-9.6c-4.79,0-9.94-5.35-11.63-7.11l-.52-.53a105.65,105.65,0,0,1-8.1-9.37,104.48,104.48,0,0,1,8.1-9.37l.52-.54c1.69-1.75,6.85-7.1,11.62-7.1h9.6v8.33a1.93,1.93,0,0,0,1.13,1.75,1.88,1.88,0,0,0,.8.17,1.93,1.93,0,0,0,1.26-.47l14.5-12.5a1.93,1.93,0,0,0,0-2.93l-14.5-12.5a1.93,1.93,0,0,0-3.2,1.47v8.33h-9.6c-8.32,0-15.08,7-17.62,9.64l-.42.43a104.62,104.62,0,0,0-7.52,8.54,119.82,119.82,0,0,0-9.16-10.45c-5.19-5.19-11.9-8.16-18.4-8.16H412.51a4.17,4.17,0,0,0,0,8.33H426c4.24,0,8.92,2.14,12.5,5.72a122,122,0,0,1,9.75,11.29,123.1,123.1,0,0,1-9.75,11.29c-3.59,3.58-8.26,5.72-12.5,5.72H412.51a4.17,4.17,0,1,0,0,8.33H426c6.5,0,13.21-3,18.4-8.17a120.46,120.46,0,0,0,9.16-10.45,103.82,103.82,0,0,0,7.52,8.53l.41.42c2.55,2.65,9.31,9.66,17.64,9.66h9.6v8.33a1.93,1.93,0,0,0,1.13,1.75,1.88,1.88,0,0,0,.8.17,1.93,1.93,0,0,0,1.26-.47l14.5-12.5a1.93,1.93,0,0,0,0-2.93Z" />
    </svg>
  );
}

export default CaretUpDown;
